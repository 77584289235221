<template>
	<el-row id="al">
		测试
	</el-row>
</template>

<script>
	import {mapGetters} from 'vuex'
	export default {
		computed: {
			...mapGetters(['states'])
		},
		data() {
			return {
			
			}
		},
		mounted(){
			this.init()
		},
		methods:{
			init(){
				// this.$post({url:'',data:{},IS:false})
			}
		}
	}
</script>

<style>
	#al{
		height: 100vh;
		width: 100%;
		background-color: #fff;
		padding: 10px 15px;
	}
</style>